import React, { useState, useEffect } from "react";
import { setDescription, setTitle } from "../../utils";
import bannerImg from "../../assets/images/banners/GE-Banner.png";
import styles from "./Events.module.css"
import Banner from "../../components/Banner/Banner";
import { getEvents } from "../../utils";
import { Loader } from "../../components/Loader/Loader";
import { Error } from "../../components/Error/Error";
import EventImages from "./EventImages";
import Btn from "../../components/Button/Button";
import calendarIcon from "../../assets/images/events/calendar-icon.svg";
import locationIcon from "../../assets/images/events/map-pin-icon.svg";
import greenLeadersImg from "../../assets/images/training/green_leaders_graphic.png";
import cutCarbonImg from "../../assets/images/training/cut_carbon_cut_costs_graphic.png";
import greenTechPeerImg from "../../assets/images/training/green_tech_peer_network_graphic.png";
import greenTechTrainingImg from "../../assets/images/training/green_tech_training_vouchers_graphic.png";
import journeyToNetZeroImg from "../../assets/images/training/journey_to_net_zero_graphic.png";
import netZeroPeerGroupsImg from "../../assets/images/training/net_zero_peer_groups_graphic.png";
import sustainabilityChampionsImg from "../../assets/images/training/sustainability_champions_network_graphic.png";
import marketplaceImg from "../../assets/images/events/marketplace.svg";
import ExpanderIcon from "../../assets/images/events/expand-arrows.svg";
import { Link } from "react-router-dom";
import FilterSelect from "../../components/FilterSelect/FilterSelect";
import Toggle from "../../components/Toggle/Toggle";

import {
    addEventsToLocalStorage,
    getEventsFromLocalStorage
} from "../../utils";

window.addEventListener("beforeunload", () => {
    localStorage.removeItem("event-results");
});


export const Events = () => {

    const [inputs, setInputs] = useState({ events: null });
    const [fullData, setFullData] = useState({ events: null });
    const [trainingData, setTrainingData] = useState({ events: null });
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [locationFilter, setLocationFilter] = useState("");
    const [yearFilter, setYearFilter] = useState("");
    const [toggle, setToggle] = useState("Events");
    const [resetFilter, setResetFilter] = useState(false);
    const [moreTraining, setMoreTraining] = useState(false);

    const handleClick = () => {
        if (moreTraining === false) {
            setMoreTraining(true);
        } else {
            setMoreTraining(false);
        }
    }

    useEffect(() => {
        setTitle("Green Economy | Events and Training: Green skills development");
        setDescription("Green Economy events develop skills for green leaders and managers and simplifies the technologies at the heart of the net zero transition.")
    }, []);

    useEffect(() => {
        const fetchEvents = async () => {
            try {
                setIsLoading(true);
                let data = getEventsFromLocalStorage();
                if (!data) {
                    data = await getEvents();
                }
                setFullData({ "events": data });
                toggleEvents(data,true);
                addEventsToLocalStorage(data);
            } catch (error) {
                setError({ message: "Uh oh, looks like something went wrong" });
            } finally {
                setIsLoading(false);
            }
        };
        fetchEvents();
    }, []);// eslint-disable-line react-hooks/exhaustive-deps


    const filterEventList = async () => {
        let filteredData = null;
        const data = toggle === "Events" ? fullData : trainingData;
        if (locationFilter !== "") {
            if (locationFilter === "All Locations")
                filteredData = data.events;
            else
                filteredData = data.events.filter((input) => input.location === locationFilter)
        }
        if (yearFilter !== "") {
            if (yearFilter === "All Dates")
                filteredData = data.events;
            else
                filteredData = data.events.filter((input) => input.eventMonthYear === yearFilter)
        }
        if (locationFilter !== "" && yearFilter !== "") {
            if (locationFilter === "All Locations" && yearFilter === "All Dates")
                filteredData = data.events;
            else {
                if (locationFilter !== "All Locations" && yearFilter !== "All Dates")
                    filteredData = data.events.filter((input) => input.eventMonthYear === yearFilter && input.location === locationFilter);
                else if (locationFilter !== "All Locations" && yearFilter === "All Dates")
                    filteredData = data.events.filter((input) => input.location === locationFilter)
                else
                    filteredData = data.events.filter((input) => input.eventMonthYear === yearFilter)
            }
        }
        setResetFilter(false);
        setInputs({ "events": filteredData });
    }

    useEffect(() => {
        if (locationFilter !== "" || yearFilter !== "")
            filterEventList();
    }, [locationFilter, yearFilter]);// eslint-disable-line react-hooks/exhaustive-deps


    const toggleEvents = async (data, loading) => {
        let toggledData = []
        if (data) {
            if (toggle === "Events")
                toggledData = data.filter((item) => item.category !== "EDUCATION");
            else {
                toggledData = data.filter((item) => item.category === "EDUCATION");
                setTrainingData({ "events": toggledData})
            }
            if (loading === false) {
                setResetFilter(true);
            }
                
            setInputs({ "events": toggledData });
        }
    }

    useEffect(() => { 
        
        toggleEvents(fullData.events, false);
    }, [toggle]);// eslint-disable-line react-hooks/exhaustive-deps
    

    return (
        <>
            <Banner
                title="GREEN ECONOMY EVENTS AND TRAINING"
                text="BITESIZE LEARNING, PEER NETWORKS AND"
                subText="COLLABORATION OPPORTUNITIES"
                src={bannerImg}
                alt="Events"
                color='white'
                bgcolour="#02be7c"
            />
            {/* Events section */}
            <section className={styles.eventsContainer}>
                <div className={styles.eventsContentContainer}>
                    {toggle === "Events" &&
                        <>
                            <h2><b>Green Economy Events</b></h2>
                            <p>Green Economy delivers a range of events, networking, workshops and briefings for businesses.</p>
                            <p>Join us online or in-person for sessions focused on business growth best practice, tech-demystification, peer networking and collaboration, policy and trends analysis and meet the buyer events. </p>    
                        </>
                    }
                    {toggle === "Training" && 
                        <>
                            <h2><b>Green Economy Training</b></h2>
                            <p>Green Economy offers a range of training opportunities to upskill you and your workforce in how to make the most of the net zero transition. </p>
                            <p>We provide practical support to decarbonise your business, whilst supporting green technologies and services businesses to improve their competitiveness and to grow. </p>
                        </>
                    }

                    <Toggle setToggle={setToggle }/>

                </div>
            </section>
            {/* Further training section top */}
            <section className={`${styles.bgGrey}`}>
                {toggle === "Training" &&
                <div className={`${styles.centreContent}`}>
                        <div className={`${styles.buttonExpander}`} onClick={handleClick}>
                            <p>Explore course content</p><img src={ExpanderIcon}></img>
                        </div>
                </div>
                }
                {moreTraining && toggle === "Training" &&
                    <>
                    <section className={`${styles.eventsContainer} ${styles.paddingT}`}>
                        <h4 className={`${styles.paddingB} ${styles.trainingTitle} ${styles.width60}`}>Net zero training and skills</h4>

                        <div className={styles.grid3Cols}>

                            <div className={`${styles.trainingCard} ${styles.shadow}`}>
                                <div className={`${styles.colContentContainer}`}>
                                    <div>
                                        <img alt="Journey to net zero" className={`${styles.imageWidth_70}`} src={journeyToNetZeroImg} />
                                    </div>
                                    <h4 className={`${styles.pink}`}>Journey to net zero</h4>
                                    <p>Upskill your workforce, understand net zero and implement effective carbon reduction plans to save your business money on this accredited level 3 course.</p>
                                </div>
                                <div className={`${styles.cardCTA}`}>
                                    <a href={process.env.REACT_APP_GI_URI + "/services/transition-to-net-zero/training/journey-to-net-zero/"}>
                                        <Btn text="Find out more" color="Member" />
                                    </a>
                                </div>
                            </div>
                            <div className={`${styles.trainingCard} ${styles.shadow}`}>
                                <div className={`${styles.colContentContainer}`}>
                                    <div>
                                        <img alt="Net Zero Peer Groups" className={`${styles.imageWidth_70}`} src={netZeroPeerGroupsImg} />
                                    </div>
                                    <h4 className={`${styles.pink}`}>Net Zero Peer Groups</h4>
                                    <p className="mb-4">Tackle your business challenges and accelerate decarbonisation with peer learning and applied design thinking, over three, short, action led workshops.</p>
                                </div>
                                <div className={`${styles.cardCTA}`}>
                                    <a href={process.env.REACT_APP_GI_URI + "/services/transition-to-net-zero/training/net-zero-peer-group/"}>
                                        <Btn text="Find out more" color="Member" />
                                    </a>
                                </div>
                            </div>
                            <div className={`${styles.trainingCard} ${styles.shadow}`}>
                                <div className={`${styles.colContentContainer}`}>
                                    <div>
                                        <img alt="Sustainability Champions Network" className={`${styles.imageWidth_70}`} src={sustainabilityChampionsImg} />
                                    </div>
                                    <h4 className={`${styles.pink}`}>Sustainability Champions network</h4>
                                    <p>Connect with business leaders sharing knowledge and inspiration on their net zero experiences to embed peer-led, sustainable strategies you're your own organisation.</p>
                                </div>
                                <div className={`${styles.cardCTA}`}>
                                    <a href={process.env.REACT_APP_GI_URI + "/services/transition-to-net-zero/training/sustainability-champions/"}>
                                        <Btn text="Find out more" color="Member" />
                                    </a>
                                </div>
                            </div>

                        </div>

                        <h4 className={`${styles.paddingTB} ${styles.trainingTitle} ${styles.width50}`}>Grow your green tech and services business with group learning and training opportunities</h4>

                        <div className={styles.grid2Cols}>
                            <div className={`${styles.trainingCard} ${styles.shadow}`}>
                                <div className={`${styles.colContentContainer}`}>
                                    <div>
                                        <img alt="Green Leaders" className={`${styles.imageWidth_60}`} src={greenLeadersImg} />
                                    </div>
                                    <h4 className={`${styles.pink}`}>Green Leaders</h4>
                                    <p>Drive profitability and business resilience with our series of 90 minute workshops focusing on business growth and finance, marketing, leadership and sustainability.</p>
                                </div>
                                <div className={`${styles.cardCTA}`}>
                                    <a href={process.env.REACT_APP_GI_URI + "/green-leaders"}>
                                        <Btn text="Find out more" color="Member" />
                                    </a>
                                </div>
                            </div>
                            <div className={`${styles.trainingCard} ${styles.shadow}`}>
                                <div className={`${styles.colContentContainer}`}>
                                    <div>
                                        <img alt="Green Tech Training Vouchers" className={`${styles.imageWidth_60}`} src={greenTechTrainingImg} />
                                    </div>
                                    <h4 className={`${styles.pink}`}>Green Tech Training Vouchers</h4>
                                    <p className="mb-4">Apply for a limited number of training vouchers on offer for businesses operating in West Yorkshire that install solar, battery and heat pump technologies in commercial or domestic properties.</p>
                                </div>
                                <div className={`${styles.cardCTA}`}>
                                    <a href={process.env.REACT_APP_GI_URI + "/services/transition-to-net-zero/training/green-tech-training-vouchers/"}>
                                        <Btn text="Find out more" color="Member" />
                                    </a>
                                </div>
                            </div>
                         
                        </div>
                    </section>
                   
                    </>
                }
                <div className={`${styles.eventsContainer} ${styles.padTB2}`}>

                    <div className={`${styles.eventsFilters}`}>
                        {toggle === "Events" &&
                            <>
                            <h3>Events</h3>
                            </>
                        }
                        {toggle === "Training" &&
                            <>
                                <h3>Training</h3>
                            </>
                        }
                        <div>
                            <FilterSelect
                                type="year"
                                filterValue={setYearFilter}
                                data={fullData}
                                name="Dates"
                                initialValue="All Dates"
                                resetFilter={resetFilter }
                            />
                            <FilterSelect
                                type="location"
                                filterValue={setLocationFilter}
                                data={fullData}
                                name="location"
                                initialValue="All Locations"
                                resetFilter={resetFilter }
                            />
                        </div>
                    </div>

                    {error &&
                         <Error error={error} id="event-error" page="events-page" />
                    }

                    {isLoading &&
                         <Loader page="events" />
                    }

                    {!isLoading && inputs && inputs.events && inputs.events.length === 0 &&
                        <div>No Events found</div>
                    }

                    {!isLoading && inputs && inputs.events &&
                        <div className={styles.eventsCards}>
                                {inputs.events.map((event) => (
                                    <div className={`${styles.eventsCard}`} key={event.id}>
                                        <a href={"https://greeneconomy.zohobackstage.eu/" + event.eventKey} target="_blank" rel="noopener noreferrer">
                                            <div className={styles.eventCardImg}>
                                                <EventImages category={event.category} />
                                                <div className={styles.cardEventDate}>
                                                    <h4>{event.eventDay}</h4>
                                                    <p>{event.eventMonthYear}</p>
                                                </div>
                                            </div>
                                        </a>
                                        <div className={styles.cardBody} style={{ maxWidth: '100%', height: 'auto' } }>
                                            <div className={styles.cardBodyLeft}>
                                                <div className={styles.cardLocation}>
                                                    <div>
                                                        <img alt="calendar" src={calendarIcon} /><p> {event.startDate} | {event.startTime} - {event.endTime}</p>
                                                    </div>
                                                    <div>
                                                        <img alt ="location" src={locationIcon} /><p> Location: <b>{event.location}</b></p>
                                                    </div>
                                                </div>
                                                <p className={styles.eventsTitle}><a href={"https://greeneconomy.zohobackstage.eu/" + event.eventKey} target="_blank" rel="noopener noreferrer">{event.name}</a></p>
                                                <p className={styles.eventsCardDetail}>{event.summary}</p>
                                            </div>
                                            <div className={styles.cardBodyRight}>
                                                {event.eventKey &&
                                                    <div className={ styles.findOutMore } >
                                                        <a href={"https://greeneconomy.zohobackstage.eu/" + event.eventKey} target="_blank" rel="noopener noreferrer">
                                                            <Btn text="Find out more" color="Member" />
                                                        </a>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                ))}
                        </div>
                    }

                </div>
            </section>

            {/* Marketplace section */}
            <section className={styles.bgGreen}>
                <div className={`${styles.eventsContainer}`}>
                    <div className={`${styles.grid2Cols} ${styles.noGap}`}>
                        <div className={`${styles.imageContainer}`}>
                            <img alt="marketplace" className={`${styles.imageWidth_50}`} src={marketplaceImg} />
                        </div>
                        <div className={`${styles.colContentContainer}`}>
                            <h4 className={`${styles.drkGreen}`}>Marketplace</h4>
                            <p className="mb-4">Use our directory of installers, equipment suppliers and solution providers to make an informed decision on your sustainability projects and support your local green economy</p>
                            <Link to={`/marketplace`} >
                                <Btn text="Take me there" color="MemberWhite" />
                            </Link>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};
